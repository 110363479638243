import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Tabs, TabsManager } from '@react-md/tabs';
import { CrossFade } from '@react-md/transition';
import { Typography } from '@react-md/typography';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { FeatureKeys as FF, useFeatureFlag } from '@components/feature-flags';
import { getErrorMessage } from '@components/form-field';
import { OrderForm, AppSettings, ShippingTypes } from '@types';
import { composeFullAddress } from '@utils';
import { useMounted } from '@hooks';
import { selectContentEditableMode } from '@modules/app';
import { updateDeliveryType, updateShippingRates } from '@modules/order';
import OrderPickupTab from './order-pickup-tab';
import OrderDeliveryTab from './order-delivery-tab';
import { OrderFormShipment } from './order-form-shipment';
import { GetShippingRatesSignature } from './hooks';
import { deliveryId } from './helpers';

import styles from './order-form-delivery.module.scss';

interface OrderFormDeliveryProps {
    settings: AppSettings;
    getRates: GetShippingRatesSignature;
}

export const OrderFormDelivery: React.FC<OrderFormDeliveryProps> = ({ settings, getRates }) => {
    const dispatch = useAppDispatch();
    const tabsRef = useRef<HTMLDivElement>(null);
    // const order = useAppSelector(selectOrderData);

    const {
        watch,
        getValues,
        setValue,
        formState: { errors },
    } = useFormContext<OrderForm>();

    const { t } = useTranslation();

    const pickupAddressShown = useFeatureFlag(FF.OrderPage.PickupAddressShown);
    const pickupWithAddress = settings.use_local_pickup && settings.require_address_on_pickup;

    const [shippingType] = watch(['shippingType']);

    const shippingTypeIsNotSelected = shippingType === ShippingTypes.EmptyByDefault;
    const shippingTypeIsDelivery = shippingType === ShippingTypes.Delivery;
    const shippingTypeIsPickup = shippingType === ShippingTypes.Pickup;
    const isContentEditableMode = useAppSelector(selectContentEditableMode);

    useEffect(() => {
        dispatch(updateDeliveryType({ shippingTypeIndex: shippingType }));
    }, [dispatch, shippingType]);

    // initial rates request if we have default fields
    useEffect(() => {
        getRates(getValues, errors, 'shippingType');
    }, [getRates, errors, getValues]);

    useEffect(() => {
        const lastErrorDeliveryOnly = Object.keys(errors).length === 1 && errors?.shippingType;
        if (lastErrorDeliveryOnly && tabsRef.current) {
            tabsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [errors]);

    // don't want the first render to trigger the animation, but all changes afterwards should.
    const rendered = useMounted();

    const tab = shippingTypeIsDelivery ? (
        !pickupWithAddress && (
            <OrderDeliveryTab getRates={getRates}>
                <OrderFormShipment />
            </OrderDeliveryTab>
        )
    ) : shippingTypeIsPickup && pickupAddressShown ? (
        <OrderPickupTab>{composeFullAddress(settings.company_address)}</OrderPickupTab>
    ) : null;

    return (
        <div className={styles.box}>
            <Typography id={deliveryId('title')} type="headline-3" component="div" className="margin-bottom">
                <FeatureContent contentKey={deliveryId('title')} fallback={t('titles.delivery', 'Delivery')} />
            </Typography>

            <FeatureMarkup
                contentKey={deliveryId('markup_top')}
                wrapper={props => <div className="medium-margin-bottom" {...props} />}
            />

            {settings.use_local_pickup && settings.use_delivery && (
                <>
                    {errors.shippingType?.message && (
                        <Typography
                            type="headline-6"
                            className={cn('rmd-typography--theme-error', styles.validationErrText)}
                        >
                            {getErrorMessage(t, errors.shippingType.message)}
                        </Typography>
                    )}
                    <TabsManager
                        activeIndex={shippingType}
                        onActiveIndexChange={index => {
                            setValue('shippingType', index, { shouldValidate: true });
                            getRates(getValues, errors, 'shippingType');
                        }}
                        tabs={[
                            {
                                id: deliveryId('pickup_tab'),
                                children: (
                                    <FeatureContent
                                        contentKey={deliveryId('pickup_tab')}
                                        fallback={t('buttons.localPickup', 'Local pickup')}
                                    />
                                ),
                                disabled: isContentEditableMode,
                            },
                            {
                                id: deliveryId('delivery_tab'),
                                children: (
                                    <FeatureContent
                                        contentKey={deliveryId('delivery_tab')}
                                        fallback={t('buttons.withDelivery', 'With delivery')}
                                    />
                                ),
                                disabled: isContentEditableMode,
                            },
                        ]}
                        tabsId={deliveryId('tabs')}
                    >
                        <Tabs
                            ref={tabsRef}
                            className={cn(styles.tabs, {
                                [styles.validationErrOnTabs]: errors?.shippingType?.message,
                            })}
                            disableTransition
                        />
                    </TabsManager>
                </>
            )}

            {tab && (
                <CrossFade key={shippingType} appear={rendered}>
                    {tab}
                </CrossFade>
            )}

            {pickupWithAddress && !shippingTypeIsNotSelected && (
                <OrderDeliveryTab getRates={getRates} className="margin-top">
                    {shippingTypeIsDelivery && <OrderFormShipment />}
                </OrderDeliveryTab>
            )}
        </div>
    );
};
