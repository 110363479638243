import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import trim from 'lodash/trim';
import { OrderForm, AppSettings, ShippingTypes } from '@types';
import { getNodeIdComposer } from '@utils';
import { RootPrefix } from '../constants';
import { OrderFormId } from './constants';

export const formId = getNodeIdComposer(OrderFormId);
export const contactId = getNodeIdComposer(RootPrefix, 'contact_section');
export const deliveryId = getNodeIdComposer(RootPrefix, 'delivery_section');
export const shipmentId = getNodeIdComposer(RootPrefix, 'shipment_section');
export const billingId = getNodeIdComposer(RootPrefix, 'billing_section');
export const notesId = getNodeIdComposer(RootPrefix, 'notes_section');

export const getInitialShippingType = (settings: AppSettings, hasDeliveryAddress: boolean) => {
    const { use_local_pickup, use_delivery } = settings;

    if (use_delivery && use_local_pickup) {
        return ShippingTypes.EmptyByDefault;
    }
    if (use_delivery && (hasDeliveryAddress || !use_local_pickup)) {
        return ShippingTypes.Delivery;
    }

    if (use_local_pickup) {
        return ShippingTypes.Pickup;
    }

    return undefined;
};

export const isDeliveryAddressVisible = (shippingType: ShippingTypes, settings: AppSettings) => {
    return (
        Boolean(shippingType === ShippingTypes.Delivery) ||
        (Boolean(shippingType === ShippingTypes.Pickup) && settings.require_address_on_pickup)
    );
};

export const getRatesDeps = (values: OrderForm) => {
    return {
        delivery_address: mapValues(values.delivery_address, trim),
        ...mapValues(pick(values, ['first_name', 'last_name', 'phone']), trim),
    };
};
