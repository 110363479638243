import { SHAPE_TYPE_LABELS, TOPOLOGY_TYPE_LABELS } from '@services/df/codegen-enums-labels';
import { isHoleModelDfmFeature, ModelDfmFeature } from './types';

export const getFeatureBlindDisplay = (through: string | boolean) => {
    switch (through) {
        case '': {
            return 'allow both';
        }
        case 'through':
        case true: {
            return 'not blind';
        }
        case 'not_through':
        case false: {
            return 'blind';
        }
    }
};

export const getModelFeatureLabel = (props: ModelDfmFeature) => {
    const { through, ratio, radius, angle, topology_type } = props;

    const parts = [
        TOPOLOGY_TYPE_LABELS[topology_type],
        // isHoleModelDfmFeature(props) ? FEATURE_TYPE_LABELS[props.feature_type].toLowerCase() : '',
        isHoleModelDfmFeature(props) ? SHAPE_TYPE_LABELS[props.shape_type].toLowerCase() : '',
        getFeatureBlindDisplay(through),
        angle ? `Angle:\xa0${angle}°` : '',
        radius ? `Radius:\xa0${radius}` : '',
        ratio ? `Ratio:\xa0${ratio}:1` : '',
    ].filter(Boolean);

    return parts.join(', ');
};
