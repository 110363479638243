import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Control, FieldErrors, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { AppSettings, OrderForm, ShippingTypes } from '@types';
import { Select, TextField } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
import { Icons } from '@components/icons';
import { FormField } from '@components/form-field';
import { FeatureContent, ContentKeys as FC } from '@components/feature-content';
import { getInitialState, getStates } from '@utils';
import { Countries } from '@constants';
import { billingId } from './helpers';
import { useOrderBillingAddressFormLabels } from './hooks';
import { BillingAddressFieldNames, DeliveryAddressFieldNames, OrderFormId } from './constants';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';
import { KeyboardEvent } from 'react';

import styles from './order-form-billing.module.scss';

const countries = Countries.slice();

interface OrderFormBillingAddressProps {
    country: string;
    state?: string;
    settings: AppSettings;
    control: Control<any>;
    shippingType: ShippingTypes;
    setValue: UseFormSetValue<OrderForm>;
    getValues: UseFormGetValues<OrderForm>;
    errors: FieldErrors<any>;
}

export const OrderFormBillingAddress = ({
    settings,
    errors,
    country,
    state,
    control,
    setValue,
    getValues,
    shippingType,
}: OrderFormBillingAddressProps) => {
    const { t } = useTranslation();
    const { require_address_on_pickup } = settings;

    const billingAddressLabels = useOrderBillingAddressFormLabels(t, settings);

    const handleCopyFromDeliveryAddress = () => {
        const values = getValues(DeliveryAddressFieldNames);
        values.forEach((value, i) => value && setValue(BillingAddressFieldNames[i], value));
    };

    return (
        <div className="margin-top">
            <Typography id={billingId('billing_address')} type="headline-3" component="div" className="margin-bottom">
                <FeatureContent
                    contentKey={billingId('billing_address')}
                    fallback={t('titles.billingAddress', 'Billing address')}
                />
            </Typography>

            {(shippingType !== ShippingTypes.Pickup || require_address_on_pickup) && (
                <FeatureContent
                    contentKey={billingId('copy_delivery_address')}
                    fallback={t('buttons.copyFromDeliveryAddress', 'Copy from delivery address')}
                    wrapper={props => (
                        <Button
                            theme="primary"
                            onClick={handleCopyFromDeliveryAddress}
                            className="rmd-button--flat"
                            {...props}
                        >
                            <TextIconSpacing icon={<Icons.Copy />} forceIconWrap iconAfter>
                                {props.children}
                            </TextIconSpacing>
                        </Button>
                    )}
                />
            )}

            <div className={cn('fieldset-grid', 'margin-top', styles.grid2)}>
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_address.company_name"
                    component={TextField}
                    label={billingAddressLabels['billing_address.company_name']}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_address.vat_number"
                    component={TextField}
                    label={billingAddressLabels['billing_address.vat_number']}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_address.street_address"
                    component={TextField}
                    label={billingAddressLabels['billing_address.street_address']}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_address.apartment"
                    component={TextField}
                    label={billingAddressLabels['billing_address.apartment']}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_address.city"
                    component={TextField}
                    label={billingAddressLabels['billing_address.city']}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_address.postcode"
                    component={TextField}
                    label={billingAddressLabels['billing_address.postcode']}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_address.country"
                    component={Select}
                    label={billingAddressLabels['billing_address.country']}
                    widgetProps={{
                        options: countries,
                        onChange: () => {
                            const country = getValues('billing_address.country');
                            const prevState = getValues('billing_address.state');
                            const state = getInitialState(country);

                            if (state !== prevState) {
                                setValue('billing_address.state', state);
                            }
                        },
                        labelKey: 'name',
                        valueKey: 'code',
                        onClick: cutSelectListIfTooLong,
                        onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => cutSelectListIfTooLongOnKeydown(event.key),
                    }}
                />

                {state && (
                    <FormField
                        control={control}
                        prefix={OrderFormId}
                        name={'billing_address.state'}
                        component={Select}
                        label={billingAddressLabels['billing_address.state']}
                        widgetProps={{
                            options: getStates(country),
                            labelKey: 'name',
                            valueKey: 'code',
                            onClick: cutSelectListIfTooLong,
                            onKeyDown: (event: KeyboardEvent<HTMLDivElement>) =>
                                cutSelectListIfTooLongOnKeydown(event.key),
                        }}
                    />
                )}
            </div>
        </div>
    );
};
