import { Control, FieldErrors, UseFormGetValues } from 'react-hook-form';
import { Checkbox } from '@react-md/form';
import { useTranslation } from 'react-i18next';
import { FormField } from '@components/form-field';
import { OrderForm } from '@types';
import { OrderFormId } from './constants';

interface IsCompanyOrderProps {
    isCompanyOrder: boolean;
    control: Control<any>;
    errors: FieldErrors<any>;
    getValues: UseFormGetValues<OrderForm>;
}

export const IsCompanyOrder = ({ isCompanyOrder, getValues, control, errors }: IsCompanyOrderProps) => {
    const { t } = useTranslation();
    return (
        <div>
            <FormField
                control={control}
                prefix={OrderFormId}
                name="isCompanyOrder"
                component={Checkbox}
                widgetProps={{
                    label: t('labels.isCompanyOrder', 'This is company order'),
                    defaultChecked: isCompanyOrder,
                    disableIconOverlay: true,
                    disableProgrammaticRipple: true,
                    disableRipple: true,
                }}
            />
        </div>
    );
};
