import cn from 'classnames';
import React, { forwardRef } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Label } from '@components/label';
import { Price } from '@components/price';
import { TbdLabel } from '@components/tbd';
import { Fade } from '@components/animations';
import { FilesList } from '@components/file-field';
import { Purchase } from '@types';
import { ModelCell } from '@modules/models';
import { PurchaseSpecification } from '@modules/product';
import { selectInvoiceExactTriggersTbd } from '@modules/invoice';
import { partsId } from '../helpers';

import styles from './invoice-parts.module.scss';

interface InvoicePartProps extends React.HTMLAttributes<HTMLElement> {
    purchase: Purchase;
    delay: number;
}

export const InvoicePart = forwardRef<HTMLTableRowElement, InvoicePartProps>(function InvoicePart(
    { purchase, ...rest },
    ref,
) {
    const hasDrawings = Boolean(purchase.drawing_files.length);
    const triggersTbd = useAppSelector(state => selectInvoiceExactTriggersTbd(purchase.id)(state));

    const { t } = useTranslation();

    return (
        <>
            <Fade as="tr" in className={styles.row} mountOnEnter unmountOnExit appear {...rest}>
                <td className={cn(styles.cell, styles.counter)}>
                    <Typography type="body-2" component={'div'} className={styles.number} />
                </td>

                <td className={styles.cell}>
                    <ModelCell label={purchase.product?.title} thumb={purchase.product?.thumb_120x120} />
                </td>

                <td className={styles.cell}>
                    <Typography type="body-2" component={'div'}>
                        {purchase.material_title}
                    </Typography>
                </td>

                <td className={cn(styles.cell, styles.specification)}>
                    {purchase.config_formatted ? (
                        <PurchaseSpecification fields={purchase.config_formatted_json} />
                    ) : (
                        '-'
                    )}
                </td>

                <td className={styles.cell}>
                    <Typography type="body-2" component={'div'} className="rmd-typography--right">
                        {parseFloat(purchase.discount_percents) + '%'}
                    </Typography>
                </td>

                <td className={cn(styles.cell, 'rmd-typography--right')}>
                    {triggersTbd.length ? (
                        <TbdLabel />
                    ) : (
                        <Typography type="body-2" component={'div'}>
                            <Price>{purchase.unit_price}</Price>
                        </Typography>
                    )}
                </td>

                <td className={styles.cell}>
                    <Typography type="body-2" component={'div'} className="rmd-typography--right">
                        {purchase.count}
                    </Typography>
                </td>

                <td className={cn(styles.cell, 'rmd-typography--right')}>
                    {triggersTbd.length ? (
                        <TbdLabel />
                    ) : (
                        <Typography type="body-2" component={'div'}>
                            <Price>{purchase.total_price}</Price>
                        </Typography>
                    )}
                </td>
            </Fade>

            {hasDrawings && (
                <Fade as="tr" in className={styles.row} mountOnEnter unmountOnExit appear {...rest}>
                    <td className={styles.cell} />
                    <td colSpan={7} className={styles.drawings}>
                        {/*todo wait for order_products*/}
                        <Label>{t('subtitles.drawings', 'Technical drawings')}</Label>
                        <FilesList
                            expandToggleId={partsId('files_expander', purchase.id)}
                            fileObjects={purchase.drawing_files}
                            expandedByDefault={false}
                        />
                    </td>
                </Fade>
            )}
        </>
    );
});
